
import router from '@/router';
import { generalStore } from '@/store';
import { ref } from 'vue';
import { OrderDetails } from '@/models/Order';
import { api } from '@/services/Api';
import Q from 'q';
import swal from 'sweetalert2';
import { onBeforeRouteLeave } from 'vue-router';
import useOrder from '@/modules/useOrder';
import { useI18n } from 'vue-i18n';
import OrderHeader from '@/components/Order/OrderHeader.vue';

export default {
    components: {
        OrderHeader
    },
    async setup() {
        swal.showLoading();
        const { t } = useI18n();
        const { canLeave } = useOrder();
        onBeforeRouteLeave(async to => {
            return await canLeave(to);
        });
        const order = ref(new OrderDetails());
        order.value = generalStore.getters.getNewOrder;
        if (!order.value || !order.value.priceTypeId) {
            router.replace({ name: 'neworder' });
            swal.close();
            return;
        }
        const apiResult = await api.getPriceTypeProducts(order.value.priceTypeId, order.value.businessClientId);
        await Q.delay(400);
        const products = apiResult.data || [];

        const productSelect = {
            value: 0,
            placeholder: t('placeholder.rangersInTheWild'),
            valueProp: 'id',
            trackBy: 'text',
            label: 'text',
            required: true,
            searchable: true,
            options: products
        };

        function getProductName(productid: string) {
            const product = products.find(x => x.id == productid);
            return product?.text || '';
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                if (order.value.newproduct) {
                    order.value.newproduct.productName = getProductName(order.value.newproduct.productId);
                }
                router.push({ name: 'addorderproductslot' });
            }
        };

        function backBtn() {
            if (order.value.id) {
                router.push({ name: 'order', params: { id: order.value.id } });
                return;
            }
            router.push({ name: 'neworder' });
        }
        swal.close();

        function onSelectProduct(productId: string, productName: string) {
            if (order.value.newproduct.calendarDate) {
                generalStore.commit('clearCalendarDate');
            }
            if (order.value.newproduct) {
                order.value.newproduct.productName = productName;
            }
            order.value.newproduct.productId = productId;
            router.push({ name: 'addorderproductslot' });
        }

        return {
            order,
            backBtn,
            submitForm,
            productSelect,
            products,
            onSelectProduct
        };
    }
};
